<template>
  <div class="box">
    <h2 class="divide divide2">Assigned Products</h2>
    <div class="filter">
      <div class="search-box">
        <div class="input-box">
          <input
            v-on:keyup.enter="handleSearch"
            v-model="filter.marketing_name_or_sale_user"
            type="text"
            placeholder="Search by Product Name or Sales Representative"
          />
        </div>
        <div class="btn" @click="handleSearch">Search</div>
        <div class="btn clearbtn" shape="circle" @click="clearSearch">
          Clear
        </div>
      </div>
    </div>

    <div class="wrap" style="position: relative;">
      <Table
        :loading="loading"
        :columns="columns"
        :data="list"
        size="small"
        style="overflow: inherit;"
      >
        <template slot-scope="{ row: item }" slot="saleUsers">
          <Tooltip :disabled="item.fallNames.length > 15 ? false : true">
            <div slot="content" style="white-space:normal">
              <div v-for="(user, index) in item.sale_users" :key="index">
                •
                <span>
                  {{ user.first_name }} {{ user.last_name }}
                  <template v-if="item.sale_users.length !== index + 1"
                    >,</template
                  >
                </span>
              </div>
            </div>
            <div class="elliuser">{{ item.fallNames }}</div>
          </Tooltip>
        </template>
        <template slot-scope="{ row: item }" slot="reque">
          <span stlye="color:#ff6600">{{ item.questions_count }} Requests</span>
        </template>
        <template slot-scope="{ row: item }" slot="action">
          <Button
            style="color:#ff5050;border:1px solid #ff5050;"
            size="small"
            @click="removeProd(item)"
            shape="circle"
            icon="md-remove"
          ></Button>
        </template>
      </Table>
    </div>

    <div class="page" v-if="total">
      <Page
        size="small"
        :page-size="5"
        :current="page"
        :total="total"
        show-total
        @on-change="pageChange"
      />
    </div>

    <Button
      size="small"
      class="addunssign"
      @click="showUnassignPop = true"
      style="margin-top:20px;color:#f57c00;border:1px solid #f57c00"
    >
      + Add Products to Member
    </Button>

    <!-- modal -->
    <Modal
      width="80%"
      class="unassign"
      v-model="showUnassignPop"
      title="Assign Products to Member"
      footer-hide
      @on-cancel="cancel"
    >
      <h4>SPECIFIC PRODUCT(S)</h4>

      <div class="search-box">
        <div class="input-box">
          <Icon type="ios-search" class="search-icon" />
          <input
            v-model="popfilter.marketing_name_or_sale_user"
            v-on:keyup.enter="handleSearchUnss"
            type="text"
            placeholder="Search by Product Name or Sales Representative"
          />
        </div>
        <div class="btn" @click="handleSearchUnss">Search</div>
        <div class="btn clearbtn" shape="circle" @click="clearSearchUnss">
          Clear
        </div>
      </div>
      <div class="content">
        <Table
          border
          ref="selection"
          :columns="colUnassignPros"
          :data="unlists"
          :loading="poploading"
          height="239"
          size="small"
          @on-selection-change="seledItems"
        >
          <template slot-scope="{ row: item }" slot="productType">
            <div>{{ item.product_type || "--" }}</div>
          </template>
          <template slot-scope="{ row: item }" slot="saleUsers">
            <div class="saleTags" v-if="item.sale_users.length">
              <p
                class="saleTag"
                v-for="(user, index) in item.sale_users"
                :key="index"
              >
                {{ user.first_name }} {{ user.last_name }}
              </p>
            </div>
            <div v-else class="saleTags">
              --
            </div>
          </template>
        </Table>
      </div>
      <div class="page" style="text-align:right">
        <Page
          size="small"
          :current="sspage"
          :page-size="5"
          :total="unsignForm.total"
          show-total
          @on-change="pageChangeUnssign"
        />
      </div>
      <div
        class="btn"
        style="margin-top:15px;text-align: right;color: #ff6600;"
      >
        <Button
          v-if="btnloading"
          loading
          style="width:150px;border: 1px solid #ff6600;color:#ff6600"
          >Submitting...</Button
        >
        <Button
          v-else
          style="width:150px;border: 1px solid #ff6600;color:#ff6600"
          @click="sendAsk"
          >Assign Products</Button
        >
      </div>
    </Modal>
  </div>
</template>

<script>
import api from "@/api";
const {
  _querySaleProduct,
  _delSaleProduct,
  _queryUnassignProduct,
  _saleProduct
} = api;

export default {
  name: "documents",
  props: ["userid"],
  created() {
    this.querySaleList();
    this.queryUnassignProductList();
  },

  data() {
    return {
      page: 1,
      sspage: 1,
      limit: 5,
      total: 0,
      list: [],
      loading: false,
      btnloading: false,
      filter: {},

      columns: [
        {
          title: "PRODUCT NAME",
          key: "marketing_name"
        },
        {
          title: "SALES REPRESENTATIVE",
          slot: "saleUsers"
        },
        {
          title: "REQUESTS",
          slot: "reque"
        },
        {
          title: "Action",
          key: "action",
          width: 100,
          align: "center",
          slot: "action"
        }
      ],
      poploading: false,
      showUnassignPop: false,
      popfilter: {},
      colUnassignPros: [
        {
          type: "selection",
          width: 60,
          align: "center"
        },
        {
          title: "PRODUCT NAME",
          key: "marketing_name"
        },
        {
          title: "PRODUCT TYPE",
          width: 150,
          slot: "productType"
        },
        {
          title: "SALES REPRESENTATIVE",
          slot: "saleUsers",
          width: 400
        }
      ],
      unlists: [],
      unsignForm: {
        total: 0
      }
    };
  },

  methods: {
    edit(item) {
      this.$router.push(`/documents/${item.id}/edit`);
    },

    handleSearch() {
      this.page = 1;
      this.querySaleList();
    },
    clearSearch() {
      this.page = 1;
      this.filter.marketing_name_or_sale_user = "";
      this.querySaleList();
    },

    querySaleList() {
      this.loading = true;

      _querySaleProduct({
        id: this.userid,
        page: this.page,
        limit: this.limit,
        ...this.filter
      })
        .then(response => {
          const { data } = response;
          this.list = data.data;
          this.list.forEach((item, index) => {
            var strName = "";
            if (item.sale_users.length) {
              item.sale_users.forEach(ite => {
                strName += ite.first_name + " " + ite.last_name + ",";
              });
              this.$set(
                this.list[index],
                "fallNames",
                strName.substr(0, strName.length - 1)
              );
            } else {
              this.$set(this.unlists[index], "fallNames", "--");
            }
          });
          this.total = data.total;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    pageChange(page) {
      this.page = page;
      this.querySaleList();
    },
    pageSizeChange(limit) {
      this.limit = limit;
      this.querySaleList();
    },
    removeProd(item) {
      this.$Modal.confirm({
        loading: true,
        title: "Confirm Delete",
        content: "Are you sure you want to delete this relationship?",
        onOk: () => {
          _delSaleProduct({
            id: this.userid,
            proid: item.id
          })
            .then(() => {
              this.$Message.success("success");
              this.querySaleList(); // refresh
            })
            .catch(({ message }) => {
              this.$Message.error(message);
            })
            .finally(() => {
              this.$Modal.remove();
            });
        }
      });
    },
    handleSearchUnss() {
      this.sspage = 1;
      this.queryUnassignProductList();
    },
    clearSearchUnss() {
      this.sspage = 1;
      this.popfilter.marketing_name_or_sale_user = "";
      this.queryUnassignProductList();
    },
    pageChangeUnssign(page) {
      this.sspage = page;
      this.queryUnassignProductList();
    },
    queryUnassignProductList() {
      this.poploading = true;
      _queryUnassignProduct({
        id: this.userid,
        page: this.sspage,
        limit: this.limit,
        ...this.popfilter
      })
        .then(response => {
          const { data } = response;
          this.unlists = data.data;
          this.unlists.forEach((item, index) => {
            var strName = "";
            if (item.sale_users.length) {
              item.sale_users.forEach(ite => {
                strName += ite.first_name + " " + ite.last_name + ",";
              });
              this.$set(
                this.unlists[index],
                "fallNames",
                strName.substr(0, strName.length - 1)
              );
            } else {
              strName = "--";
              this.$set(this.unlists[index], "fallNames", "--");
            }
          });
          this.unsignForm.total = data.total;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.poploading = false;
        });
    },
    seledItems(sels) {
      this.popfilter.products = sels.map(item => item.id);
    },
    sendAsk() {
      this.btnloading = true;
      _saleProduct({
        id: this.userid,
        products: this.popfilter.products
      })
        .then(() => {
          this.$Message.success("Submit success!");
          this.showUnassignPop = false;
          this.querySaleList();
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {
          this.btnloading = false;
        });
    },
    cancel() {}
  }
};
</script>

<style lang="less" scoped>
.box {
  .divide2 {
    margin: 30px 0 20px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  .filter {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    justify-content: space-between;
    .total {
      color: #333;
      .tip {
      }
      .num {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .search-box {
      flex: 1;
      height: 40px;
      display: flex;
      .input-box {
        position: relative;
        flex: 1;
        height: 100%;
        background: #fff;
        line-height: initial;
        padding-left: 20px;
        padding-right: 20px;
        border: 1px solid #dfdfdf;
        border-right: none;
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
        input {
          width: 100%;
          height: 100%;
          outline: none;
          background: transparent;
          border: none;
          color: #444;
          caret-color: #888;

          &::-webkit-input-placeholder {
            color: #bdcada;
          }
          &:-moz-placeholder {
            color: #bdcada;
          }
          &::-moz-placeholder {
            color: #bdcada;
          }
          &:-ms-input-placeholder {
            color: #bdcada;
          }
        }
      }
      .btn {
        cursor: pointer;
        background: #fff;
        color: @primary-color;
        border: 1px solid @primary-color;
        border-top-right-radius: 40px;
        border-bottom-right-radius: 40px;
        padding: 0px 20px;
        display: flex;
        align-items: center;
        justify-self: center;
        line-height: initial;
        height: 100%;
      }
      .clearbtn {
        margin-left: 10px;
        color: #666;
        border: 1px solid #ddd;
        border-radius: 20px;
      }
    }
  }
  .page {
    margin: 10px 0;
    display: flex;
    justify-content: flex-end;
  }
}

.unassign {
  h4 {
    font-size: 12px;
    padding-bottom: 10px;
  }
  .content {
    margin-top: 10px;
  }
  .ivu-table-wrapper {
    margin-bottom: 15px;
  }
  .ivu-table {
    font-size: 12px;
  }

  .ivu-table th,
  .ivu-table td {
    height: 30px;
    border: none;
    border-bottom: 1px solid #f2f2f2;
  }

  .search-box {
    display: flex;
    align-items: center;
    height: 35px;
    // width: 600px;
    .input-box {
      position: relative;
      flex: 1;
      // width: 600px;
      height: 100%;
      background: #fff;
      line-height: initial;
      padding-left: 40px;
      padding-right: 20px;
      border: 1px solid #dfdfdf;
      border-right: none;
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
      .search-icon {
        position: absolute;
        top: 7px;
        left: 10px;
        font-size: 20px;
        color: #888;
      }
      input {
        width: 100%;
        height: 100%;
        outline: none;
        background: transparent;
        border: none;
        color: #444;
        caret-color: #888;

        &::-webkit-input-placeholder {
          color: #bdcada;
        }
        &:-moz-placeholder {
          color: #bdcada;
        }
        &::-moz-placeholder {
          color: #bdcada;
        }
        &:-ms-input-placeholder {
          color: #bdcada;
        }
      }
    }
    .tit {
      padding: 0 10px;
      margin-left: 10px;
      &.first {
        padding-left: 0;
        margin-left: 0;
      }
    }
    .ivu-btn-circle {
      color: @primary-color;
      border: 1px solid @primary-color;
      margin-left: 20px;
    }
    .btn {
      cursor: pointer;
      background: #fff;
      color: @primary-color;
      border: 1px solid @primary-color;
      border-top-right-radius: 40px;
      border-bottom-right-radius: 40px;
      padding: 0px 20px;
      display: flex;
      align-items: center;
      justify-self: center;
      line-height: initial;
      height: 100%;
    }
    .clearbtn {
      margin-left: 10px;
      color: #666;
      border: 1px solid #ddd;
      border-radius: 20px;
    }
    .clearbtn2 {
      margin-left: 190px;
      color: #fff;
      background-color: @primary-color;
      border: 1px solid @primary-color;
      border-radius: 6px;
    }
    .type-wrap {
      position: absolute;
      right: 20px;
      color: #ffca94;
      font-size: 20px;
      cursor: pointer;
      /deep/ .ivu-icon-ios-list {
        position: relative;
        top: 3px;
        font-size: 32px;
        font-weight: bold;
      }
      .cur {
        color: @primary-color;
      }
    }
  }
}
</style>
<style lang="less">
.elliuser {
  width: 135px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.saleTags {
  display: flex;
}
.saleTag {
  flex: none;
  background-color: #ffe5d4;
  padding: 2px 5px;
  color: #ff6600;
  margin-right: 4px;
  border-radius: 4px;
}
</style>
