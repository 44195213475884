<template>
  <div class="relative sc-width">
    <Spin v-if="loading" fix></Spin>

    <Modal
      v-model="modal"
      title="Change Avatar"
      footer-hide
      :mask-closable="false"
    >
      <Alert show-icon>
        We recommend that you upload a photo with a minimum dimension of 400 x
        400.
      </Alert>

      <Cropper
        :stencil-props="{
          aspectRatio: 1
        }"
        classname="cropper"
        :src="image"
        ref="cropper"
      />

      <Button class="mr-20" @click="$refs.file.click()">
        <input
          v-show="false"
          type="file"
          ref="file"
          @change="uploadImage($event)"
          accept="image/*"
        />
        Choose image
      </Button>

      <Button
        :loading="uplodLoading"
        :disabled="!image"
        @click="crop"
        type="primary"
      >
        Upload
      </Button>
    </Modal>

<!--    <div class="breadcrumb">-->
<!--      <div class="item" @click="$router.push('/company-profile')">-->
<!--        Company Profile-->
<!--      </div>-->
<!--      <Icon style="margin:0 5px" type="ios-arrow-forward" />-->
<!--      <div class="item" @click="$router.push('/company-profile/members')">-->
<!--        Members-->
<!--      </div>-->
<!--      <Icon style="margin:0 5px" type="ios-arrow-forward" />-->
<!--      <div class="item active">{{ first_name }} {{ last_name }}</div>-->
<!--    </div>-->

    <div class="pd-20">
      <div class="member-name">
        {{ first_name }} {{ last_name }}
        <div class="float-btn">
          <Button
            v-if="!isEdit"
            @click="isEdit = true"
            icon="md-create"
            ghost
            type="primary"
            >Edit Member</Button
          >

          <Button
            :loading="saveEditLoading"
            v-if="isEdit"
            @click="saveEdit()"
            icon="md-create"
            ghost
            type="primary"
            >Save</Button
          >
        </div>
      </div>
      <Row :gutter="20">
        <i-col span="8">
          <div class="white-bg">
            <div class="img-box">
              <img v-if="user_avatar" :src="user_avatar" alt="" />
            </div>
            <Button
              @click="modal = true"
              long
              icon="md-create"
              ghost
              type="primary"
              >Edit Photo</Button
            >
          </div>
        </i-col>
        <i-col span="16">
          <div class="white-bg">
            <h2 class="divide">Basic Info</h2>

            <Row v-if="isEdit" :gutter="20">
              <i-col span="12">
                <div class="k-text">FIRST NAME</div>
                <div class="input">
                  <Input v-model="first_name" />
                </div>
              </i-col>
              <i-col span="12">
                <div class="k-text">LAST NAME</div>
                <div class="input">
                  <Input v-model="last_name" />
                </div>
              </i-col>
              <i-col span="12">
                <div class="k-text">ROLE</div>

                <Select v-model="pagerole">
                  <Option value="Admin">Administrator</Option>
                  <Option value="Member">Member</Option>
                </Select>
              </i-col>
              <i-col span="12">
                <div class="k-text">JOB TITLE</div>
                <div class="input">
                  <Select v-model="job_title">
                    <Option
                      v-for="item in jobTitleList"
                      :key="item"
                      :value="item"
                      >{{ item }}</Option
                    >
                  </Select>
                  <Input
                    v-model="job_title_other"
                    v-if="job_title === 'Other'"
                  />
                </div>
              </i-col>
            </Row>

            <Row v-else :gutter="20">
              <i-col span="12">
                <div class="k-text">NAME</div>
                <div class="text">{{ first_name }} {{ last_name }}</div>
              </i-col>
              <i-col span="12">
                <div class="k-text">EMAIL</div>
                <div class="text">{{ email }}</div>
              </i-col>
              <i-col span="12">
                <div class="k-text">ROLE</div>
                <div class="text" v-if="pagerole === 'Admin'">
                  Administrator
                </div>
                <div class="text" v-else>Member</div>
              </i-col>
              <i-col span="12">
                <div class="k-text">JOB TITLE</div>
                <div class="text" v-if="job_title === 'Other'">
                  {{ job_title_other }}
                </div>
                <div class="text" v-else>{{ job_title }}</div>
              </i-col>
            </Row>

            <div class="assigned" v-if="role === 'exhibitor'">
              <Assigned :userid="id"></Assigned>
            </div>
          </div>
        </i-col>
      </Row>
    </div>
  </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import { dataURLtoBlob, blobToFile } from "@/utils/helper";
import S3 from "aws-s3";
import api from "@/api";
import Assigned from "@/views/member/Assigned";
import { mapState } from "vuex";
const { _queryMember, _editMember, _registerInfo } = api;

const uploader = new S3({
  bucketName: "connexima",
  dirName: "images",
  region: "us-west-1",
  accessKeyId: "AKIA6KR6UZLLVZYGDEZM",
  secretAccessKey: "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"
});

export default {
  computed: {
    ...mapState("user", ["role", "is_administrator", "company_id"])
  },
  components: {
    Cropper,
    Assigned
  },
  created() {
    const id = this.$route.params.id;
    this.id = id;

    this.loading = true;

    _registerInfo()
      .then(response => {
        const {
          data: { job_title }
        } = response;

        this.jobTitleList = job_title;

        _queryMember({ id })
          .then(response => {
            const { data } = response;

            this.email = data.email;
            this.first_name = data.first_name;
            this.last_name = data.last_name;
            this.user_avatar = data.avatar?.url;

            if (this.jobTitleList.includes(data.job_title)) {
              this.job_title = data.job_title;
            } else {
              this.job_title = "Other";
              this.job_title_other = data.job_title || "Other";
            }

            this.pagerole = data.is_administrator ? "Admin" : "Member";
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      })
      .catch(({ message }) => {
        this.$Message.error(message);
      });
  },
  mounted() {
    console.log(this.user);
  },
  data() {
    return {
      jobTitleList: [],
      job_title_other: "",

      uplodLoading: false,
      image: null,
      modal: false,
      loading: false,

      isEdit: false,
      saveEditLoading: false,

      email: "",
      first_name: "",
      last_name: "",
      user_avatar: "",
      job_title: "",
      pagerole: null
    };
  },

  methods: {
    crop() {
      const { canvas } = this.$refs.cropper.getResult();
      const base64Data = canvas.toDataURL();
      const blob = dataURLtoBlob(base64Data);
      const file = blobToFile(blob);

      this.uplodLoading = true;
      uploader
        .uploadFile(file)
        .then(data => {
          this.modal = false;
          this.avatar = data.location;
          this.updateAvatar(data.location);
        })
        .catch(() => {
          this.$Message.error("upload error");
        })
        .finally(() => {
          this.uplodLoading = false;
        });
    },

    uploadImage(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = e => {
          this.image = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },

    saveEdit() {
      this.saveEditLoading = true;
      let title;

      if (this.job_title === "Other") {
        title = this.job_title_other;
      } else {
        title = this.job_title;
      }

      if (!title) {
        return this.$Message.error("Job title is required");
      }

      _editMember({
        first_name: this.first_name,
        last_name: this.last_name,
        user_avatar: this.user_avatar,
        job_title: title,
        role: this.pagerole,
        id: this.id
      })
        .then(() => {
          this.$Message.success("success");
          this.saveEditLoading = false;
          this.isEdit = false;
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        });
    },

    updateAvatar(url) {
      let title;
      if (this.job_title === "Other") {
        title = this.job_title_other;
      } else {
        title = this.job_title;
      }

      if (!title) {
        return this.$Message.error("Job title is required");
      }

      _editMember({
        first_name: this.first_name,
        last_name: this.last_name,
        user_avatar: url,
        job_title: title,
        role: this.pagerole,
        id: this.id
      })
        .then(() => {
          this.user_avatar = url;
          this.$Message.success("success");
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        });
    }
  }
};
</script>

<style lang="less" scoped>
.breadcrumb {
  color: lighten(@primary-color, 30%);
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 14px 24px;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  background: #fff;

  .item {
    cursor: pointer;
    &.active {
      color: @primary-color;
      cursor: initial;
    }
  }
}

.cropper {
  width: 100%;
  height: 300px;
  border: solid 1px #ccc;
  margin-bottom: 20px;
}

.white-bg {
  border: 1px solid #ccc;
  border-radius: 15px;
  background: #fff;
  padding: 20px;
}

.img-box {
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    max-width: 200px;
    margin-bottom: 20px;
  }
}

.member-name {
  color: #000;
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  /*display: flex;*/
  align-items: center;
  justify-content: center;
  .float-btn {
    /*position: absolute;*/
    /*right: 20px;*/
    float: right;
  }
}

.divide {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.k-text {
  margin-bottom: 10px;
}
.text {
  width: 100%;
  overflow: hidden;
  background: #f7f7f7;
  padding: 6px 10px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  min-height: 34px;
}
.relative {
  margin: 78px auto 0 auto;
}
</style>
